export enum MetaType {
	MOVIE = 'movie',
	SERIES = 'series',
}

interface Popular {
	moviedb: number;
	trakt: number;
	stremio: number;
	stremio_lib: number;
}
interface Trailer {
	source: string;
	type: 'Trailer' | string;
}
interface TrailersStream {
	title: string;
	ytId: string;
}
interface Link {
	name: string;
	category: 'imdb' | string;
	url: string;
}

export interface MetaVideo {
	name: string;
	season: number;
	number: number;
	firstAired: Date;
	tvdb_id: number;
	rating: string;
	overview: string;
	thumbnail: string;
	id: string;
	released: Date;
	episode: number;
	description: string;
}

export interface Meta {
	order: number;
	name: string;
	poster: string;
	type: MetaType;
	logo: string;
	id: string;
}

export interface FilmInfo {
	id: string;
	name: string;
	releaseInfo: string;
	type: MetaType;
	poster: string;
	imdbRating: string;
	popularity: number;
}

export interface MetaSearch {
	id: string;
	imdb_id: string;
	type: MetaType;
	name: string;
	releaseInfo: string;
	poster: string;
	links: Link[];
}

export interface KitsuSearch {
	id: string;
	type: MetaType;
	animeType: 'TV' | 'movie';
	name: string;
	aliases: string[];
	description: string;
	releaseInfo: string;
	runtime: string;
	imdbRating: string;
	genres: string[];
	logo: string;
	poster: string;
	background: string;
	trailers: Trailer[];
	links: Link[];
}

export interface Streaming {
	name: string;
	title: string;
	url: string;
	behaviorHints: {
		bingeGroup: string;
		filename: string;
	};
}

export interface Transcode {
	url: string;
	subtitles: string[];
	audios: string[];
}

export enum MetaName {
	IMDB = 'IMDB',
	KITSU = 'KITSU',
}
export interface MetaExtractor {
	name: MetaName;
	id: string;
	season?: number;
	episode?: number;
	fullId: string;
	type: MetaType;
}
