import { useEffect, useState } from 'react';

interface GoogleAdProps {
	client: string;
	slot: string;
	format?: 'auto' | string;
	responsive?: boolean;
	style?: React.CSSProperties;
}
const GoogleAd = (props: GoogleAdProps) => {
	const { client, slot, format = 'auto', responsive = true, style } = props;
	const [adLoaded, setAdLoaded] = useState(false);
	useEffect(() => {
		if (!adLoaded) {
			const ads = window.adsbygoogle || [];
			ads.push({});
			setAdLoaded(true);
			console.log('ads', ads);
		}
	}, [adLoaded]);

	return (
		<ins
			className="adsbygoogle"
			style={{
				display: 'block',
				width: '100%',
				minHeight: '250px',
				minWidth: '250px',
				...style,
			}}
			data-ad-client={client}
			data-ad-slot={slot}
			data-ad-format={format}
			data-full-width-responsive={responsive ? 'true' : 'false'}></ins>
	);
};

export default GoogleAd;
