import axios from 'axios';
import { toast } from 'react-toastify';
import { UserConfigsType } from './config';
import { Meta, MetaType } from './interfaces/Meta';
import { RSubtitle } from './interfaces/Subtitle';

export enum InputType {
	KITSU = 'Kitsu',
	IMDB = 'IMDb',
}

const request = axios.create({
	baseURL: process.env.REACT_APP_APP_URL || 'http://localhost:8080',
});
const APIs = {
	async searchMetas(input: string, type: InputType): Promise<Meta[]> {
		const res = await request.get('/meta/search', {
			params: { name: input, type: type === InputType.IMDB ? 'IMDb' : 'kitsu' },
		});
		if (res.status !== 200) {
			return [];
		}
		return res.data.content.map((i: any, index: number) => ({
			order: index,
			...i,
		}));
	},
	async getSubtitles(
		type: MetaType,
		id: string,
		config: UserConfigsType
	): Promise<RSubtitle[]> {
		return request
			.get(`/subtitle/${id}/list`, {
				params: {
					type,
					languages: config?.language,
					transProvider: config?.transProvider,
					apiKey: config?.apiKey,
					location: config?.region,
				},
			})
			.then((res) => {
				if (res.data.content.length === 0) {
					toast(`Can't found any subtitles`, { type: 'info' });
				}
				return res.data.content;
			})
			.catch((e) => {
				toast('The server is busy now, can you try later?', {
					type: 'warning',
				});
				return [];
			});
	},
};

export default APIs;
