import { Autocomplete, TextField } from '@mui/material';
import langs from 'langs';
import { forwardRef } from 'react';
import { UseFormClearErrors, UseFormSetError } from 'react-hook-form';
import { FormFields } from '../pages/Home';

type OptionType = {
	label: string;
	id: string;
};
interface LanguagesSelectProps {
	error: boolean;
	onChange(val: string | null): void;
	setError: UseFormSetError<FormFields>;
	clearErrors: UseFormClearErrors<FormFields>;
	defaultValue: string | undefined;
}
const options: OptionType[] = langs.all().map((i) => ({
	id: i.name.toLowerCase(),
	label: i.local.startsWith('isiN') ? i.name : i.local,
}));

const LanguagesSelect = forwardRef(function (props: LanguagesSelectProps, ref) {
	const { error, onChange, setError, clearErrors, defaultValue } = props;
	const optionDefault = options.find((i) => i.id === defaultValue);
	return (
		<Autocomplete
			ref={ref}
			disablePortal
			options={options}
			defaultValue={optionDefault}
			fullWidth
			onChange={(_, val) => {
				if (!val) {
					setError('language', {
						type: 'required',
						message: 'Please select your language',
					});
					onChange(null);
				} else {
					onChange(val.id);
					clearErrors('language');
				}
			}}
			renderInput={(params) => (
				<TextField
					placeholder="Select your language"
					error={error}
					{...params}
				/>
			)}
		/>
	);
});

export default LanguagesSelect;
