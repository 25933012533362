import { MetaType } from './Meta';

export enum SubProvider {
	SUBSCENE = 'subscene',
	OPENSUBTITLES = 'opensubtitles',
	KITSUNEKKO = 'kitsune',
	AIO_SUBTITLE = 'AIO-Subtitle',
}

export interface RSubtitle {
	id: string;
	lang: string;
	iso639_1: string;
	iso639_2: string;
	title: string;
	label: string;
	language: string;
	provider: SubProvider;
	translateTo: string | null;
	url: string;
}

export interface RSubtitlesLanguages {
	[language: string]: RSubtitle[];
}

export interface Cue {
	identifier: string;
	start: number;
	end: number;
	text: string;
	styles: string;
}

export interface UploadVttParams {
	type: MetaType;
	id: string;
	vttContent: string;
	fileName: string;
	language: string;
}
