import {
	AttachFile as AttachFileIcon,
	CheckCircleOutline as CheckCircleOutlineIcon,
	FileDownload as FileDownloadIcon,
	FindInPage as FindInPageIcon,
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
	Box,
	Chip,
	Divider,
	FormControl,
	FormHelperText,
	FormLabel,
	Grid2 as Grid,
	Link,
	List,
	ListItemButton,
	ListItemText,
	MenuItem,
	Select,
	Stack,
	TextField,
	Typography,
} from '@mui/material';
import { orange, purple } from '@mui/material/colors';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import APIs, { InputType } from '../api';
import Logo from '../assets/AI-sub.png';
import backgroundImage from '../assets/reiphantomhive1.webp';
import GoogleAd from '../components/GoogleAd';
import LanguagesSelect from '../components/LanguagesSelect';
import SearchMetasSelect from '../components/SearchMetasSelect';
import {
	default as ConfigLanguage,
	TransProvider,
	default as UserConfigs,
} from '../config';
import { generateSubFileName } from '../helpers';
import { useLazyBackground } from '../hooks/useLazyBackground';
import { Meta, MetaType } from '../interfaces/Meta';
import { RSubtitle, SubProvider } from '../interfaces/Subtitle';

export interface FormFields {
	type: InputType;
	language: string | null;
	meta: Meta | null;
	season?: number;
	episode?: number;
	transProvider?: TransProvider;
	apiKey?: string;
	region?: string;
}

export default function App() {
	const [subs, setSubs] = useState<RSubtitle[]>([]);
	const [sub, setSub] = useState<RSubtitle | null>(null);
	const [loading, setLoading] = useState(false);
	const subsRef = useRef<HTMLDivElement>(null);
	const [downloading, setDownloading] = useState(false);
	const containerRef = useRef<HTMLDivElement>(null);
	const isVisible = useLazyBackground(containerRef);
	const userConfig = UserConfigs.get();
	const {
		handleSubmit,
		register,
		watch,
		setValue,
		setError,
		clearErrors,
		formState: { errors, defaultValues },
	} = useForm<FormFields>({
		defaultValues: { ...userConfig, meta: null },
		mode: 'onChange',
	});
	const watchValues = watch();

	const loadSubs = async (values: FormFields) => {
		setLoading(true);
		const { episode, language, meta, season, apiKey, region, transProvider } =
			values;
		if (meta && language) {
			ConfigLanguage.save({ language, transProvider, apiKey, region });
			let input;
			if (meta.type === MetaType.SERIES) {
				input = meta.id.startsWith('kitsu')
					? `${meta.id}:${episode}`
					: `${meta.id}:${season}:${episode}`;
			} else {
				input = meta.id;
			}
			const data = await APIs.getSubtitles(meta.type, input, {
				language,
				apiKey,
				region,
				transProvider:
					transProvider !== TransProvider.NONE ? transProvider : undefined,
			});
			setSubs(data);
			subsRef.current?.scrollIntoView({
				behavior: 'smooth',
				block: 'start',
			});
		} else {
			setSubs([]);
		}
		setLoading(false);
	};

	const onSelectSub = (val: RSubtitle) => {
		setSub(val);
	};
	const onDownloadSub = async () => {
		try {
			if (!sub) {
				toast('No sub file was found', { type: 'error' });
				return;
			}
			setDownloading(true);
			const response = await fetch(sub.url);

			if (!response.ok) {
				throw new Error('Network response was not ok');
			}

			const blob = await response.blob();
			const link = document.createElement('a');
			const objectUrl = URL.createObjectURL(blob);

			link.href = objectUrl;
			const { meta, season, episode } = watchValues;
			if (!meta) {
				return toast('Please select a movie/series', { type: 'error' });
			}
			const filename = generateSubFileName(meta, sub, season, episode);
			link.download = filename;
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);

			URL.revokeObjectURL(objectUrl);
			toast(`Downloaded ${filename}`, { type: 'success' });
			setDownloading(false);
		} catch (error: any) {
			console.error('Error downloading file:', error.message);
			toast(`Can't download file, try another sub`, { type: 'error' });
			setDownloading(false);
		}
	};
	useEffect(() => {
		setSubs([]);
		setSub(null);
		if (watchValues.meta?.type === MetaType.MOVIE) {
			setValue('season', undefined);
			setValue('episode', undefined);
		}
	}, [
		watchValues.type,
		watchValues.language,
		watchValues.meta,
		watchValues.season,
		watchValues.episode,
		setValue,
	]);

	useEffect(() => {
		if (watchValues.transProvider === TransProvider.NONE) {
			setValue('apiKey', undefined);
			setValue('region', undefined);
		}
	}, [watchValues.transProvider, setValue]);

	return (
		<Grid
			ref={containerRef}
			container
			sx={{
				background: isVisible
					? `url(${backgroundImage}) no-repeat center center`
					: 'none',
				backgroundAttachment: 'fixed',
				backgroundPosition: 'center',
				WebkitBackgroundSize: 'cover',
				backgroundSize: 'cover',
				transition: 'background-image 0.5s ease-in-out',
			}}>
			<Grid size={{ xl: 4.5, lg: 3.5, md: 3, sm: 2.5, xs: 0 }}>
				<GoogleAd
					client="ca-pub-1080435181519092"
					slot="6207407669"
					key="google-1"
					style={{ width: '100%', height: 'auto', maxHeight: '100vh' }}
				/>
			</Grid>
			<Grid size={{ xl: 3, lg: 5, md: 6, sm: 7, xs: 12 }}>
				<Stack
					sx={{
						background: 'white',
						alignSelf: 'center',
						width: '100%',
						borderRadius: 3,
						boxShadow: 1,
						verticalAlign: 'baseline',
						my: 3,
					}}>
					<Box
						sx={{
							p: 2,
							textAlign: 'center',
							margin: 'auto',
						}}>
						<Box component="img" src={Logo} width={120} alt="aiosubtitle" />
						<Typography variant="h4" fontWeight="bold" color={orange['800']}>
							AIO Subtitle
						</Typography>
						<Typography variant="caption" textAlign="right">
							Version: 1.0.0
						</Typography>
						<Typography>
							Developed by{' '}
							<a
								target="_blank"
								href="https://dev-namnv.github.io"
								style={{ color: purple[600] }}
								rel="noopener noreferrer">
								Dev.NamNV
							</a>
						</Typography>
						<Typography
							textAlign="start"
							variant="subtitle1"
							mt={1}
							px={1}
							color="textPrimary">
							Welcome to AIO Subtitle! Download free subtitles for your favorite
							movies, TV show and easy to download. If you find this service
							helpful, please support me to keep going:{' '}
							<Link
								href="https://coindrop.to/liamng"
								target="_blank"
								rel="noopener noreferrer">
								support me 🍭
							</Link>
						</Typography>
					</Box>
					<Divider sx={{ my: 1, mx: 2 }} />
					<Box px={3} mb={2}>
						<Box component="form" onSubmit={handleSubmit(loadSubs)}>
							<Typography variant="h6" mb={2}>
								Search
							</Typography>
							<Grid container spacing={2}>
								<Grid size={6}>
									<FormControl fullWidth error={!!errors.type} required>
										<FormLabel>Search via</FormLabel>
										<Select
											defaultValue={InputType.IMDB}
											{...register('type', { required: true })}>
											<MenuItem value={InputType.IMDB}>IMDb</MenuItem>
											<MenuItem value={InputType.KITSU}>Kitsu</MenuItem>
										</Select>
									</FormControl>
								</Grid>
								<Grid size={6}>
									<FormControl fullWidth error={!!errors.language} required>
										<FormLabel>Language</FormLabel>
										<LanguagesSelect
											setError={setError}
											clearErrors={clearErrors}
											error={!!errors.language}
											{...register('language', { required: true })}
											onChange={(val: string | null) =>
												setValue('language', val)
											}
											defaultValue={userConfig?.language}
										/>
										<FormHelperText>{errors.language?.message}</FormHelperText>
									</FormControl>
								</Grid>
							</Grid>
							<FormControl
								sx={{ mt: 1 }}
								error={!!errors.meta}
								fullWidth
								required>
								<FormLabel>Name</FormLabel>
								<SearchMetasSelect
									setError={setError}
									clearErrors={clearErrors}
									value={watchValues.meta}
									error={!!errors.meta}
									{...register('meta', { required: true })}
									onChange={(val) => setValue('meta', val)}
									type={watchValues.type}
								/>
								<FormHelperText>{errors.meta?.message}</FormHelperText>
							</FormControl>
							{watchValues.meta?.type === MetaType.SERIES && (
								<Grid sx={{ mt: 2 }} container spacing={2}>
									<Grid size={6}>
										<FormControl error={!!errors.season} required>
											<FormLabel>Season</FormLabel>
											<TextField
												type="number"
												error={!!errors.season}
												{...register('season', {
													required:
														watchValues.meta.type === MetaType.SERIES &&
														'Enter the season',
													min: 1,
												})}
												placeholder="Season"
												inputMode="numeric"
												inputProps={{ min: 1 }}
											/>
											<FormHelperText>{errors.season?.message}</FormHelperText>
										</FormControl>
									</Grid>
									<Grid size={6}>
										<FormControl error={!!errors.episode} required>
											<FormLabel>Episode</FormLabel>
											<TextField
												error={!!errors.episode}
												type="number"
												{...register('episode', {
													required:
														watchValues.meta?.type === MetaType.SERIES &&
														'Enter the episode',
													min: 1,
												})}
												placeholder="Episode"
												inputMode="numeric"
												inputProps={{ min: 1 }}
											/>
											<FormHelperText>{errors.episode?.message}</FormHelperText>
										</FormControl>
									</Grid>
								</Grid>
							)}
							<FormControl
								error={!!errors.transProvider}
								fullWidth
								required={false}
								sx={{ mt: 1 }}>
								<FormLabel>Translate with</FormLabel>
								<Select
									{...register('transProvider')}
									value={watchValues.transProvider}>
									{Object.values(TransProvider).map((i, index) => (
										<MenuItem key={`trans-provider-${index}`} value={i}>
											{i}
										</MenuItem>
									))}
								</Select>
								<FormHelperText>
									{errors.transProvider?.message ||
										'Select a trans provider (optional)'}
								</FormHelperText>
							</FormControl>
							{watchValues.transProvider !== TransProvider.NONE && (
								<Grid container spacing={2} mt={1}>
									<Grid
										size={
											watchValues.transProvider === TransProvider.MS ? 8 : 12
										}>
										<FormControl error={!!errors.apiKey} fullWidth>
											<FormLabel required>API key</FormLabel>
											<TextField
												error={!!errors.apiKey}
												{...register('apiKey', {
													required: true,
												})}
												variant="outlined"
												placeholder="Parse you API key in here..."
												autoComplete="off"
												defaultValue={defaultValues?.apiKey}
											/>
										</FormControl>
									</Grid>
									{watchValues.transProvider === TransProvider.MS && (
										<Grid size={4}>
											<FormControl error={!!errors.region} fullWidth>
												<FormLabel required>Region</FormLabel>
												<TextField
													error={!!errors.region}
													{...register('region', {
														required:
															watchValues.transProvider === TransProvider.MS,
													})}
													variant="outlined"
													placeholder="Location/Region"
													autoComplete="off"
													defaultValue={defaultValues?.region}
												/>
											</FormControl>
										</Grid>
									)}
								</Grid>
							)}
							<LoadingButton
								size="medium"
								loading={loading}
								type="submit"
								variant="contained"
								loadingPosition="end"
								endIcon={<FindInPageIcon />}
								sx={{ mt: 2, alignSelf: 'center', borderRadius: 2 }}>
								Find substitles
							</LoadingButton>
						</Box>
						<Divider sx={{ my: 2 }} />
						<Box>
							<Typography variant="h6" ref={subsRef}>
								Subtitles
							</Typography>
							{subs.length > 0 && (
								<Typography variant="caption">
									Total: {subs.length} {subs.length === 1 ? 'file' : 'files'}{' '}
									was found.
								</Typography>
							)}
							<List
								sx={{
									height: '100%',
									maxHeight: '240px',
									overflowY: 'auto',
									overflowX: 'hidden',
								}}
								component="nav"
								aria-label="list-subtitles">
								{subs.map((i, index) => (
									<ListItemButton
										key={i.id}
										selected={sub?.id === i.id}
										onClick={() => onSelectSub(i)}>
										{sub?.id === i.id ? (
											<CheckCircleOutlineIcon sx={{ mr: 2 }} color="success" />
										) : (
											<AttachFileIcon sx={{ mr: 2 }} />
										)}
										<ListItemText
											primary={
												<Typography
													variant="body2"
													sx={{ whiteSpace: 'nowrap' }}>{`(#${index + 1}) ${
													i.title
												}`}</Typography>
											}
											secondary={
												<Stack spacing={1} direction="row">
													<Chip
														size="small"
														color="primary"
														variant="outlined"
														label={
															i.label +
															(i.url.includes('/translate') || i.translateTo
																? ' (translate)'
																: '')
														}
													/>
													{i.provider === SubProvider.AIO_SUBTITLE && (
														<Chip
															size="small"
															color="secondary"
															label={i.provider}
														/>
													)}
												</Stack>
											}
										/>
									</ListItemButton>
								))}
								{subs.length === 0 && (
									<Typography variant="subtitle2">
										Try search again or another name
									</Typography>
								)}
							</List>
						</Box>
						<Divider sx={{ my: 2 }} />
						<Stack
							direction="column"
							justifyContent="center"
							alignItems="center"
							spacing={2}>
							<LoadingButton
								size="large"
								loading={downloading}
								loadingPosition="end"
								disabled={!sub}
								variant="contained"
								endIcon={<FileDownloadIcon />}
								onClick={onDownloadSub}>
								Download
							</LoadingButton>
						</Stack>
					</Box>
				</Stack>
			</Grid>
			<Grid size={{ xl: 4.5, lg: 3.5, md: 3, sm: 2.5, xs: 12 }}>
				<GoogleAd
					client="ca-pub-1080435181519092"
					slot="7422747996"
					key="google-2"
					style={{ width: '100%', height: 'auto', maxHeight: '100vh' }}
				/>
			</Grid>
		</Grid>
	);
}
