import { ThemeProvider } from '@emotion/react';
import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Home from './pages/Home';
import theme from './theme';

export default function App() {
	return (
		<React.StrictMode>
			<ThemeProvider theme={theme}>
				<Home />
				<ToastContainer />
			</ThemeProvider>
		</React.StrictMode>
	);
}
