export enum TransProvider {
	NONE = 'None',
	MS = 'Microsoft',
}

export type UserConfigsType =
	| undefined
	| {
			language: string;
			transProvider?: TransProvider;
			apiKey?: string;
			region?: string;
	  };

const UserConfigs = {
	save(config: UserConfigsType): void {
		localStorage.setItem('userConfig', JSON.stringify(config));
	},
	get(): UserConfigsType | undefined {
		const configString = localStorage.getItem('userConfig');
		if (!configString) return undefined;

		return JSON.parse(configString) as UserConfigsType;
	},
};

export default UserConfigs;
