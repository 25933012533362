import { Meta } from '../interfaces/Meta';
import { RSubtitle } from '../interfaces/Subtitle';

export function generateSubFileName(
	meta: Meta,
	sub: RSubtitle,
	season?: number,
	episode?: number
): string {
	return `${meta.name}${
		season && episode
			? `.S${season < 10 ? `0${season}` : season}E${
					episode < 10 ? `0${episode}` : episode
			  }`
			: ''
	}.${sub.iso639_1}.vtt`;
}
