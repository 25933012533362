import {
	Autocomplete,
	Box,
	CircularProgress,
	Stack,
	SxProps,
	TextField,
	Typography,
} from '@mui/material';
import { forwardRef, useCallback, useEffect, useState } from 'react';
import { UseFormClearErrors, UseFormSetError } from 'react-hook-form';
import APIs, { InputType } from '../api';
import { Meta } from '../interfaces/Meta';
import { FormFields } from '../pages/Home';

interface LanguagesSelectProps {
	type: InputType;
	sx?: SxProps;
	onChange: (value: Meta | null) => void;
	error: boolean;
	value: Meta | null;
	setError: UseFormSetError<FormFields>;
	clearErrors: UseFormClearErrors<FormFields>;
}

const SearchMetasSelect = forwardRef(function (
	props: LanguagesSelectProps,
	ref
) {
	const { type, sx, onChange, error, value, setError, clearErrors } = props;
	const [inputValue, setInputValue] = useState('');
	const [metas, setMetas] = useState<Meta[]>([]);
	const [loading, setLoading] = useState(false);
	const [debouncedValue, setDebouncedValue] = useState(inputValue);

	const loadMetas = useCallback(async () => {
		if (debouncedValue.length >= 2) {
			setLoading(true);
			const data = await APIs.searchMetas(debouncedValue, type);
			if (data) {
				setMetas(data);
			} else {
				setMetas([]);
			}
			setLoading(false);
		} else {
			setMetas([]);
		}
	}, [debouncedValue, type]);

	useEffect(() => {
		loadMetas();
	}, [loadMetas]);

	useEffect(() => {
		if (inputValue.trim().length === 0) {
			setDebouncedValue('');
		}
		const handler = setTimeout(() => {
			setDebouncedValue(inputValue.trim().toLocaleLowerCase());
		}, 1000);

		return () => {
			clearTimeout(handler);
		};
	}, [inputValue]);

	useEffect(() => {
		const find = metas.find((meta) =>
			meta.name
				.toLocaleLowerCase()
				.startsWith(debouncedValue.toLocaleLowerCase())
		);
		if (!find || !debouncedValue.length) {
			onChange(null);
		}
	}, [metas, debouncedValue]);

	// Reset metas when change Type
	useEffect(() => {
		setMetas([]);
		setInputValue('');
		onChange(null);
	}, [type]);
	return (
		<Autocomplete
			ref={ref}
			fullWidth
			sx={sx}
			value={value}
			options={metas}
			noOptionsText="Enter a movie/series name"
			filterOptions={(options) => options.sort((a, b) => a.order - b.order)}
			onInputChange={(_, newInputValue) => {
				setInputValue(newInputValue);
			}}
			onChange={(_, newValue) => {
				if (!newValue) {
					setError('meta', { type: 'required', message: 'Please select one' });
				} else {
					clearErrors('meta');
				}
				onChange(newValue || null);
			}}
			getOptionLabel={(option) => option.name}
			loading={loading}
			renderOption={(props, option) => {
				const { key, ...optionProps } = props;
				return (
					<Stack
						direction="row"
						key={option.id}
						component="li"
						sx={{
							'& > img': { mr: 2, flexShrink: 0 },
						}}
						{...optionProps}>
						<Box
							component="img"
							loading="lazy"
							width="80px"
							height="120px"
							srcSet={option.poster}
							src={option.poster}
							alt=""
						/>
						<Stack
							direction="column"
							alignItems="start"
							height="100%"
							justifyContent="start"
							spacing={2}>
							<Typography variant="caption">
								#{option.type.toUpperCase()}
							</Typography>
							<Typography>{option.name}</Typography>
						</Stack>
					</Stack>
				);
			}}
			renderInput={(params) => (
				<TextField
					value={inputValue}
					error={error}
					{...params}
					label="Search by name"
					InputProps={{
						...params.InputProps,
						endAdornment: (
							<>
								{loading ? (
									<CircularProgress color="inherit" size={20} />
								) : null}
								{params.InputProps.endAdornment}
							</>
						),
					}}
				/>
			)}
		/>
	);
});

export default SearchMetasSelect;
